import Head from 'next/head';

import { useThemeSettings } from '@/hooks';

import { getCssVariables } from './utils';

// TODO: Figure out if we can load the stylesheets in `_document` to make Next happy
function Branding() {
    const themeSettings = useThemeSettings();
    const variables = getCssVariables(themeSettings);

    return (
        <Head>
            <link
                rel="preload"
                href="/fonts/GothamUltra.woff2"
                as="font"
                type="font/woff2"
                crossOrigin=""
            />
            <link
                rel="preload"
                href="/fonts/GothamBold.woff2"
                as="font"
                type="font/woff2"
                crossOrigin=""
            />
            <link
                rel="preload"
                href="/fonts/GothamBook.woff2"
                as="font"
                type="font/woff2"
                crossOrigin=""
            />
            {variables.length > 0 && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `:root {${variables.map((variable) => variable).join(';')}}`,
                    }}
                />
            )}
            <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            <link rel="icon" sizes="228x228" href="/icon-228x228.png"></link>
            <meta name="msapplication-TileImage" content="/icon-228x228.png" />
            <meta name="msapplication-TileColor" content={themeSettings.headerBackgroundColor} />
            <meta name="theme-color" content={themeSettings.headerBackgroundColor} />
        </Head>
    );
}
export default Branding;
